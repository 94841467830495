import { array, type Input, parse } from "valibot";
import { pushAtSortPosition } from "array-push-at-sort-position";
import { toRefs, reactive } from "vue";
import isErrorWithMessage from "./isErrorWithMessage";
import { convertToNumber } from "./string";
import {
  PRICE_PER_SET,
  PRICE_PER_PERSON,
  PRICE_PER_PACKAGE,
} from "~/constants";
import {
  RuleSchema,
  type PackageRule,
  KidsPriceV2Schema,
  type Pack,
  type PackageCardProps,
  type SupportedPackageType,
  type KidsPriceV2,
  type SupportedPricingTypeSym,
  type DynamicPricing,
} from "~/types/Pack";
import dayjs from "~/lib/dayjs";
import type { BookingService } from "~/types/Booking";
import { useHandleError } from "~/composables/useHandleError";

const packageOrder = function (type: SupportedPackageType) {
  if (type === "ayce") {
    return 1;
  } else if (type === "bfp") {
    return 2;
  } else if (type === "pp") {
    return 3;
  } else if (type === "xp") {
    return 4;
  } else if (type === "hs") {
    return 5;
  } else if (type === "hah") {
    return 6;
  } else if (type === "sm") {
    return 7;
  }
  return 999;
};

export const packageTypeOrder = function packageTypeOrder(
  arrayOfPackageType: SupportedPackageType[]
) {
  return arrayOfPackageType.sort((a, b) => {
    return packageOrder(a) - packageOrder(b);
  });
};

export function pricingTypeLabel(type: string) {
  if (type === "per_person") {
    return "netPerson";
  } else {
    return "netSet";
  }
}

export function packagePricingRule(
  { adult = 0, name = "" },
  rules: PackageRule[]
) {
  try {
    const parseRules = parse(array(RuleSchema), rules);
    // selecting pricing rule only depend on adult size
    // force adult to be at least 1
    const pax = adult > 0 ? adult : 1;
    if (parseRules && parseRules.length) {
      if (parseRules.length === 1) {
        return parseRules[0];
      }
      const selectedRules = parseRules.filter((rule) => {
        const minSeat = convertToNumber(rule?.minSeat || 0);
        const maxSeat = // ios sending null as "Infinity"
          rule.maxSeat === null ||
          rule.maxSeat === undefined ||
          (typeof rule.maxSeat === "string" && rule.maxSeat === "Infinity")
            ? 9999
            : convertToNumber(rule.maxSeat || 0);
        return minSeat <= pax && pax <= maxSeat;
      });
      if (selectedRules.length) {
        return selectedRules[0];
      }
      // throw new Error(`No package rules based on adult found, pax ${pax}`);
    }
    throw new Error("No package rules found");
  } catch (err) {
    const message = useHandleError({
      errorLevel: "critical",
      defaultErrorMessage: "failed get package rules",
      err,
      errorPath: "helpers/pack/packagePricingRules",
      object: {
        packageName: name,
        packageRules: rules,
        adult,
      },
    });
    return message;
  }
}

export function packagePrice({
  name,
  adult = 0,
  rules,
  kidsPriceRules,
  useKidsPriceRules = false,
  pricingTypeSym,
}: {
  name: string;
  adult: number;
  rules?: PackageRule[];
  useKidsPriceRules?: boolean;
  kidsPriceRules?: KidsPriceV2[];
  pricingTypeSym?: SupportedPricingTypeSym | "";
}) {
  try {
    if (!rules) {
      throw new Error("Missing pack rules");
    }
    const selectedRule = packagePricingRule({ adult, name }, rules);
    if (typeof selectedRule === "string") {
      return selectedRule;
    }
    const adultPrice = selectedRule.price?.replace(",", "");

    const price: {
      adultPrice: string | undefined;
      kidsPrice: number;
    } = {
      adultPrice: "0",
      kidsPrice: 0,
    };
    switch (pricingTypeSym) {
      //  PRICE_PER_SET =  for  hungry at home
      // PRICE_PER_PACKAGE  =  for party pack & xp
      //  PRICE_PER_PERSON = for ayce, bfp, and other
      case PRICE_PER_SET:
      case PRICE_PER_PACKAGE:
        price.adultPrice = adultPrice;
        price.kidsPrice = 0;
        break;
      case PRICE_PER_PERSON: {
        price.adultPrice = adultPrice;
        const kidsPrice =
          useKidsPriceRules &&
          Array.isArray(kidsPriceRules) &&
          kidsPriceRules.length > 0
            ? getKidsPriceFromRules({ kidsPriceRules })
            : price;
        price.kidsPrice = kidsPrice ? +kidsPrice : 0;
        break;
      }
      default:
        price.adultPrice = adultPrice;
        price.kidsPrice = 0;
        break;
    }
    return price;
  } catch (err) {
    if (isErrorWithMessage(err)) {
      return err.message;
    }
    return "faild get package price";
  }
}

export function getMaxPriceKids(arr: KidsPriceV2[]) {
  if (!Array.isArray(arr)) {
    return 0;
  }
  if (arr.length === 0) {
    return 0;
  }
  const sorted = [...arr].sort((a, b) => {
    const price1 = convertToNumber(a.priceValue);
    const price2 = convertToNumber(b.priceValue);
    if (price1 < price2) {
      return 1;
    }
    if (price1 > price2) {
      return -1;
    }
    return 0;
  });
  return convertToNumber(sorted[0].priceValue);
}

export function getKidsPriceFromRules({
  kidsPriceRules,
}: {
  kidsPriceRules: Input<typeof KidsPriceV2Schema>[];
}) {
  const usedKidsPrice = kidsPriceRules.filter(
    (item) => item.priceValue !== "Free"
  );

  const highestKidsPrice = getMaxPriceKids(usedKidsPrice);
  return highestKidsPrice;
}

export function determineIsPreBook(packagesStartDate: string) {
  if (!packagesStartDate) {
    throw new Error(
      "failed determineIsPreBook, packages startDate is null or wrong formated"
    );
  }
  const startDate = dayjs(packagesStartDate, "YYYY-MM-DD");
  if (startDate.isValid()) {
    return !!startDate.isAfter(dayjs().endOf("day"));
  }
  throw new Error(
    "failed determineIsPreBook, packages startDate is null or wrong formated"
  );
}

export function determineBookable(
  packageId: string | number,
  availablePackagesIds: (string | number)[]
) {
  if (
    availablePackagesIds.length &&
    availablePackagesIds.includes(packageId) === false
  ) {
    return false;
  }
  return true;
}

export function determineExpired(
  endDate: string,
  isRestaurantExpired: boolean
) {
  const dateNow = dayjs().format("YYYY-MM-DD");
  if (isRestaurantExpired || dateNow > endDate) {
    return true;
  } else {
    return false;
  }
}

export function getLowestPricingRule(rules: PackageRule[]): PackageRule | null {
  if (rules.length === 0) {
    return null;
  }
  if (rules.length > 1) {
    const sorted = rules.sort((a, b) => {
      if (typeof a.minSeat === "number" && typeof b.minSeat === "number") {
        return a.minSeat > b.minSeat ? 1 : -1;
      }
      return 1;
    });
    return sorted[0];
  }
  return rules[0];
}

export function sortPackageByRules(packA: Pack, packB: Pack) {
  const packARules = packA.attributes.rules || [];
  const packBRules = packB.attributes.rules || [];
  const selectedRuleA = getLowestPricingRule(packARules);
  const selectedRuleB = getLowestPricingRule(packBRules);
  const priceA = convertToNumber(selectedRuleA?.price || "");
  const priceB = convertToNumber(selectedRuleB?.price || "");
  if (priceA > priceB) {
    return 1;
  }
  if (priceA < priceB) {
    return -1;
  }
  return 0;
}
export function sortPackageByRankInRestaurantScope(packA: Pack, packB: Pack) {
  const rankA = packA.attributes.rankInRestaurantScope || 0;
  const rankB = packB.attributes.rankInRestaurantScope || 0;

  if (rankA > rankB) {
    return 1;
  }
  if (rankA < rankB) {
    return -1;
  }
  return 0;
}
export function formatPackageCard(pack: Pack): PackageCardProps {
  const { quantity } = toRefs(pack.attributes);
  let isExpired = false;
  const isValidFormatDate = dayjs(
    pack.attributes.endDate,
    "YYYY-MM-DD"
  ).isValid();
  if (!isValidFormatDate) {
    isExpired = true;
  } else {
    const nowDate = dayjs(new Date()).format("YYYY-MM-DD");
    if (pack.attributes.endDate && pack.attributes.endDate < nowDate) {
      isExpired = true;
    }
  }

  const defaultDynamicPricing: DynamicPricing = {
    type: "by_day",
    rules: [],
    mode: "per_pack",
    kidsPricePolicy: "",
  };

  return reactive({
    id: pack.id,
    date: pack.attributes.endDate || "",
    image: pack.attributes.packageCoverUrl || "",
    name: pack.attributes.name || "",
    dynamicPricing: pack.attributes.dynamicPricing || defaultDynamicPricing,
    originalPrice: pack.attributes.originalPrice?.format || "",
    featured: pack.attributes.featured || false,
    preBook: determineIsPreBook(pack.attributes.startDate || ""),
    bookable: true,
    badges: pack.attributes.customLabels?.map((label) => {
      return {
        icon: label.iconUrl,
        label: label.name,
      };
    }),
    isExpired,
    type: pack.attributes.typeCode || "ayce",
    quantity,
    menuSections: pack.attributes.menuSections || [],
    comeMorePayLess: pack.attributes.comemorePayless || false,
    pricingGroups: pack.attributes.pricingGroups || "",
  });
}

export function splitPackagesByType(packages: Pack[]) {
  type DynamicObject = Record<string, Pack[]> & {
    default: Pack[];
    featured: Pack[];
  };
  const packType: Record<SupportedPackageType, DynamicObject> = {
    ayce: {
      default: [],
      featured: [],
    },
    pp: {
      default: [],
      featured: [],
    },
    bfp: {
      default: [],
      featured: [],
    },
    hah: {
      default: [],
      featured: [],
    },
    sm: {
      default: [],
      featured: [],
    },
    hs: {
      default: [],
      featured: [],
    },
    xp: {
      default: [],
      featured: [],
    },
  };

  const insertToGroup = function (type: SupportedPackageType, pack: Pack) {
    const groupName =
      pack.attributes.packageGroup?.name?.split(" ").join("-") || "";
    const isFeaturedPackage = pack.attributes.featured;

    if (isFeaturedPackage) {
      pushAtSortPosition(
        packType[type].featured,
        pack,
        sortPackageByRankInRestaurantScope,
        0
      );
      return;
    }
    if (groupName) {
      if (packType[type][groupName] === undefined) {
        packType[type][groupName] = [pack];
        return;
      }
      pushAtSortPosition(
        packType[type][groupName],
        pack,
        sortPackageByRankInRestaurantScope,
        0
      );
      return;
    }

    pushAtSortPosition(
      packType[type].default,
      pack,
      sortPackageByRankInRestaurantScope,
      0
    );
  };

  packages.forEach((pack) => {
    if (pack.attributes.typeCode) {
      insertToGroup(pack.attributes.typeCode, pack);
    }
  });
  const { ayce, bfp, hah, pp, hs, xp, sm } = packType;
  return {
    ayce,
    bfp,
    hah,
    pp,
    hs,
    xp,
    sm,
  };
}

export function getPackageTypeFullName(type: SupportedPackageType) {
  if (type === "ayce") {
    return "All You Can Eat";
  }
  if (type === "bfp") {
    return "Buffet Plus";
  }
  if (type === "hah") {
    return "Hungry@Home";
  }
  if (type === "hs") {
    return "Hungry Set";
  }
  if (type === "pp") {
    return "Party Pack";
  }
  if (type === "sm") {
    return "Set Menu";
  }
  if (type === "xp") {
    return "Xperience";
  }
  return "Invalid Package Type";
}

export function getPackageTypeColor(type: SupportedPackageType) {
  let bgColor = "bg-gray-secondary";
  if (type === "ayce") {
    bgColor = "bg-gradient-ayce";
  } else if (type === "hah") {
    bgColor = "bg-gradient-hah";
  } else if (type === "pp") {
    bgColor = "bg-gradient-pp";
  } else if (type === "xp") {
    bgColor = "bg-gradient-xp";
  }
  return bgColor;
}
export function getPackageTypeBorderColor(type: SupportedPackageType) {
  let borderColor = "hover:border-gray-secondary";
  switch (type) {
    case "ayce":
      borderColor = "hover:border-package-ayce";
      break;
    case "hah":
      borderColor = "hover:border-package-hah";
      break;
    case "pp":
      borderColor = "hover:border-package-pp";
      break;
    case "xp":
      borderColor = "hover:border-package-xp";
      break;
    default:
      break;
  }

  return borderColor;
}

export function determineBookingMethodByPackageType(
  type?: SupportedPackageType
): BookingService {
  if (type === "hah") {
    return "delivery";
  }
  return "dine_in";
}
